import '../Scss/includes.scss';

window.addEventListener('load', () => {
    const modules = import.meta.glob([
        './Content/*.js',
    ]);

    for (const path in modules) {
        new Promise((_res, _rej) => modules[path]())
    }
});
