const burger = document.querySelector(".navigation__navbar");
const burgerClose = document.querySelector(".navigation__close-container");
const menu = document.querySelector(".navigation__menu");
const linkTexts = document.querySelectorAll(".navigation__item-link");
const body = document.querySelector("body");

if (burger) {
    burger.addEventListener("click", function() {
        menu.classList.add("open");
    });
}

if (burgerClose) {
    burgerClose.addEventListener("click", function() {
        menu.classList.remove("open");
    });

    linkTexts.forEach(text => {
        text.addEventListener("click", function() {
            menu.classList.remove("open");
        });
    });
}
